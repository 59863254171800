import React from "react";
import Footer from "../../components/App/Footer";
import Layout from "../../components/App/Layout";
import Navbar from "../../components/App/Navbar";
import MainBanner from "../../components/AboutUs/MainBanner";
import Alliances from "../../components/Index/Alliances";
import logo from "../../assets/images/about-us/seaeasy-logo.png";
import { Link } from "gatsby";

const AboutSeaEasyCapital = () => {
  return (
    <Layout>
      <Navbar />
      <div className="page">
        <MainBanner />
        <section className="section-card bg-white">
          <div className="container">
            <div className="who-we-are">
              <div className="row">
                <div className="col-lg-5 text-center">
                  <img src={logo} alt="SEA Easy Capital" />
                </div>
                <div className="col-lg-7 order-lg-first">
                  <div className="section-title">
                    <h2>Who We Are</h2>
                    <p>
                      Our team of experienced professionals is committed to
                      making SEC Easy Capital as the leading , go-to marketplace
                      for business lending.
                      <br />
                      We optimise technology to minimise the cost of financial
                      intermediation with the aim of passing the benefits to our
                      borrowers and financiers.
                      <br />
                      <br />
                      SEA Easy Capital will access active financing from Banks,
                      Funds, Institutional Investors and Family Trusts.
                    </p>
                  </div>
                  <div className="meet-our-team-wrapper">
                    <Link
                      to="/about-us/our-team"
                      className="default-btn no-icon"
                    >
                      Meet Our Team
                      <span />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Alliances />
      </div>
      <Footer />
    </Layout>
  );
};

export default AboutSeaEasyCapital;
