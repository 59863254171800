import React from 'react'
import ReactWOW from 'react-wow'

const MainBanner = () => {
    return (
        <div className="digital-agency-banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="banner-wrapper-content">
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>About SEA Easy Capital</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>SEA Easy Capital (SEC) is an online market aggregator platform to facilitate businesses to release cash tied up in outstanding invoices as we manage your sales ledger, credit control and collect payments from the borrowers.</p>
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainBanner
